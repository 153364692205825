import React from 'react';
import '../styles/AboutMe.css';

const AboutMeSection = () => {
  return (
    <div className="aboutMe pt-80" id="aboutMe">
      <div className="left-section">
        <h2>About Pabita Pun</h2>
        <p>
          Passionate software developer devoted and enthusiastic about developing mobile apps, web apps, and UI/UX. A hardworking and self-motivated student with a passion for coding, designing, and problem-solving. Eager to contribute, learn, and deliver the best work within a dynamic team environment.
        </p>
      </div>
      <div className="right-section">
        <img
          src="/images/pabita.png"
          alt="PABITA PUN"
          className="profile-image"
        />
      </div>
    </div>
  );
};

export default AboutMeSection;
