import React from 'react';
import Navbar from './views/Navbar';
import CoverSection from './views/CoverSection';
import './styles/App.css'; 
import AboutMeSection from './views/AboutMeSection';
import BackgroundSection from './views/BackgroundSection';
import ProjectsSection from './views/ProjectsSection';
import SkillsSection from './views/SkillsSection';
import ContactMeSection from './views/ContactMeSection';
import CertificatesSection from './views/CertificateSection';

const App = () => {
  return (
    <div>
      <Navbar />
      <CoverSection />
      <AboutMeSection />
      <BackgroundSection />
      <CertificatesSection />
      <SkillsSection />
      <ProjectsSection />
      <ContactMeSection />
    </div>
  );
};

export default App;
