import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faAdd } from '@fortawesome/free-solid-svg-icons';
import '../styles/Background.css'
import { faSquareFontAwesome } from '@fortawesome/free-brands-svg-icons';

const backgroundData = [
  {
    icon: faSquareFontAwesome,
    institution: 'Web Development',
    degree: ' HTML, CSS, Javascript, JQuery, Bootstrap, UI/UX, ReactJS, NextJS, NodeJS, ExpressJS, Laravel'
  },
  {
    icon: faCode,
    institution: 'Mobile App Development',
    degree: 'Kotlin, Swift, ReactNative, Java, C, C++, Dart,  Android Development, Flutter, Firestore, Firebase, MongoDB'
  },
  {
    icon: faAdd,
    institution: 'Others',
    degree: 'GIT Version Control, Scrum, IDEs, Figma, UML diagrams, Adobe XD, Trello, SEO manage'
  }
];
const SkillsSection = () => {
    return (
      <div className="backgroundSection pt-80" style={{backgroundColor:'white'}} id="skills">
      <h2>SKILLS</h2>

      <div className='backgroundContent'>

        {backgroundData.map((item, index) => (

          <div key={index} className="iconContainer wd-27">
            <FontAwesomeIcon icon={item.icon} size="3x" className='icon'/>
            <h4>{item.institution}</h4>
            {item.degree && <h5 style={{ width: "70%", fontWeight: "500" }}>{item.degree}</h5>}
            {/* {item.position && <h5>{item.position}</h5>} */}
            {/* <p>{item.duration}</p> */}
          </div>

        ))}

      </div>
    </div>
      );
}

export default SkillsSection;