import React, { useState } from 'react';

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar sticky">
            <div className="logo">
                <h1>Pabita Pun</h1>
            </div>
            {/* <ul> */}
            <button className="toggle-button" onClick={toggleNavbar}>
                ☰
            </button>
            <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                <li><a href="#aboutMe">About Me</a></li>
                <li><a href="#background">Background</a></li>
                <li><a href="#myCertificates">My Certificates</a></li>
                <li><a href="#skills">Skills</a></li>
                <li><a href="#myProjects">Projects</a></li>
                <li><a href="#contactMe">Contact Me</a></li>
            </ul>

        </nav>
    );
};

export default Navbar;