import React from 'react';
import '../styles/Projects.css';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const ProjectsSection = () => {
  // Dummy data for projects
  const projectsRow1 = [
    {
      id: 1,
      name: "EV Rent",
      link: "https://github.com/Pabitapun23/EV_Rent",
      image: "./images/evRent.PNG",
    },
    {
      id: 2,
      name: "ExploreCity",
      link: "https://github.com/Pabitapun23/ExploreCity_G07",
      image: "./images/explorecity.png",
    },
    { 
      id: 3,
      name: "4Rent",
      link: "https://github.com/Pabitapun23/Group4_4Rent",
      image: "./images/4Rent.png",
    }];

    const projectsRow2 = [
      { 
        id: 4,
        name: "TIFF",
        link: "https://github.com/Pabitapun23/gbc-Intro-to-web/tree/main/Project-G07",
        image: "./images/tiff.png",
      },
    {
      id: 5,
      name: "Foodzone",
      link: "https://github.com/Pabitapun23/Foodzone",
      image: "./images/FoodZone.png",
    },
    {
      id: 6,
      name: "Pursell",
      link: "https://github.com/Pabitapun23/Pursell",
      image: "./images/Pursell.png",
    }
  ];

  const projectsRow3 = [
    { 
      id: 7,
      name: "Library App",
      link: "https://github.com/Pabitapun23/gbc-Intro-to-web/tree/main/Project-G07",
      linkName: "Github",
      image: "./images/Library.jpeg",
    },
  {
    id: 8,
    name: "FindGrant",
    link: "https://findgrant.ca/",
    linkName: "Link",
    image: "./images/FindGrant.png",
  },
  {
    id: 9,
    name: "CofiLife",
    link: "https://cofilife.com/",
    linkName: "Link",
    image: "./images/CofiLife.png",
  }
];

  // Function to navigate to GitHub page
  const navigateToGitHub = () => {
    window.open('https://github.com/Pabitapun23', '_blank'); // Open GitHub page in new tab
  };

  return (
    <Container  id="myProjects" className='projectsSection'>
      <h2 className='certificate-title'>Projects</h2>
      <Row className='projectRow'>
      {projectsRow1.map((project, index) => ( 
        <Col key={index} className="certificateContainer">
            <img
                            src={project.image}
                            alt={project.name}
                            className="certificateImage"
                            style={{height: '80%'}}
                        />
                        <p style={{fontWeight: 'bold'}}>{project.name} | <a target='_' href={project.link}>Github</a></p>
        </Col>
      ))}
      </Row>

      <Row className='projectRow'>
      {projectsRow2.map((project, index) => ( 
        <Col key={index} className="certificateContainer">
            <img
                            src={project.image}
                            alt={project.name}
                            className="certificateImage"
                        />
                        <p style={{fontWeight: 'bold'}}>{project.name} | <a target='_' href={project.link}>Github</a></p>
        </Col>
      ))}
      </Row>

      <Row className='projectRow' style={{ marginTop: '70px'}}>
      {projectsRow3.map((project, index) => ( 
        <Col key={index} className="certificateContainer">
            <img
                            src={project.image}
                            alt={project.name}
                            className="certificateImage"
                            style={{border: "1px solid white"}}
                        />
                        <p style={{fontWeight: 'bold'}}>{project.name} | <a target='_' href={project.link}>{project.linkName}</a></p>
        </Col>
      ))}
      </Row>

      <div className='btn-div'>
      <a onClick={navigateToGitHub} className='projects-btn'>See More on GitHub</a> 
      </div>
    </Container>
  );
}

export default ProjectsSection;
