import React from 'react';

const CoverSection = () => {
  return (
    <div className="cover">
      <h1>Hello, I am Pabita!</h1>
      <p>I am a Software Engineer.</p>
    </div>
  );
};

export default CoverSection;
