import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../styles/ContactMe.css';

const ContactMeSection = () => {
    return (
        <div className="contactMeSection pt-80" id='contactMe'>
          <h2>Let's Work Together!</h2>
          <p>Contact me: pabita.pun23@gmail.com</p>

          <div className='socialIconsContainer'>
            <ul className="social-icons">
              <li><a href="https://www.linkedin.com/in/pabita-pun-2390a0193/"><FontAwesomeIcon icon={faLinkedin} size="2x" className='icon'/></a></li>
              <li><a href="https://github.com/Pabitapun23"><FontAwesomeIcon icon={faGithub} size="2x" className='icon' /></a></li>
              <li><a href="https://www.instagram.com/pabita_pun/"><FontAwesomeIcon icon={faInstagram} size="2x" className='icon'/></a></li>
            </ul>
          </div>

        </div>
      );
}

export default ContactMeSection;