import React from 'react';
import '../styles/Certificates.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';


const certificateData = [
    {
      imageUrl: "./images/android.jpg",
      title: "Android development course1"
    },
    {
      imageUrl: "./images/ios.jpg",
      title: "IOS 17 Training"
    },
    {
      imageUrl: "./images/figma-essential-training.jpg",
      title: "Figma Essential Training"
    }
  ];

const CertificatesSection = () => {

    return (
        <Container style={{paddingBottom:'20px', paddingTop: '80px' }} id='myCertificates'>
            <h2 className='certificate-title'>Certificates</h2>
          <Row className='certificateRow'>
          {certificateData.map((certificate, index) => ( 
            <Col key={index} className="certificateContainer">
                <img
                                src={certificate.imageUrl}
                                alt="Certificate"
                                className="certificateImage"
                            />
                            <p>{certificate.title}</p>
            </Col>
          ))}
            
            {/* <Col className="certificateContainer">2 of 1</Col>
            <Col className="certificateContainer">3 of 1</Col> */}
          </Row>
        </Container>
      );
}

export default CertificatesSection;